<!--
 * @Author: your name
 * @Date: 2021-11-09 14:34:19
 * @LastEditTime: 2021-12-25 12:30:33
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \交建\traffic-construction-web\src\views\pages\general-user\home-page\hidden-charts.vue
-->
<template>
  <div class="box">
    <header-title header-text="安全生产目标"></header-title>
    <div style="width: 100%">
      <el-table :data="tableList" style="width: 100%;" max-height="250">
        <el-table-column align="center" width="80" type="index" label="序号"></el-table-column>
        <el-table-column
          align="center"
          show-overflow-tooltip
          prop="projectDepartmentName"
          label="工作目标内容"
        ></el-table-column>
        <el-table-column align="center" width="300" prop="planTime" label="指标"></el-table-column>
      </el-table>
    </div>
    <div class="pageDiv">
      <el-pagination
        :total="total"
        :current-page="queryForm.pageNo"
        :page-size="queryForm.pageSize"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :page-sizes="[10, 20, 30, 50]"
        layout="total, sizes, prev, pager, next"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import headerTitle from './headerTitle';
import { getPieChart } from '@/api/general-user/home-page';
export default {
  components: {
    headerTitle
  },
  data() {
    return {
      tableList: [],
      onePage: [
        { projectDepartmentName: '发生负主要责任的一般及以上生产安全责任事故', planTime: '0起' },
        { projectDepartmentName: '安全生产责任事故职工死亡或重伤', planTime: '0人' },
        { projectDepartmentName: '发生生产安全联网直报责任事件', planTime: '0起' },
        { projectDepartmentName: '完成“安全生产标准化一级”内部达标项目', planTime: '2个及以上' },
        { projectDepartmentName: '管辖项目完成交通运输厅安全生产标准化二级达标', planTime: '20%以上项目' },
        { projectDepartmentName: '季度安全会；月度安全专题会，月度例会安全学习', planTime: '不少于1次' },
        { projectDepartmentName: '完成各级岗位安全生产履职检查', planTime: '1次/季度' },
        { projectDepartmentName: '各级安全管理人员专项安全知识、技能和全员安全管理能力培训覆盖率', planTime: '100%' },
        { projectDepartmentName: '每月对所属项目安全检查，安全隐患整改合格率', planTime: '不少于1次，100%' },
        { projectDepartmentName: '完成项目安全总体策划及编制安全费用预算', planTime: '' }
      ],
      twoPage: [
        { projectDepartmentName: '重点项目每年应急演练', planTime: '1次/半年' },
        { projectDepartmentName: '瓦斯隧道引入智慧隧道安全管控一体化平台，实现现场自动化监控管理', planTime: '2处' },
        {
          projectDepartmentName:
            '制定、更新安全风险数据库、《安全风险分级管控清单》和四色安全风险分布图，并制定监控措施，落实监控责任人。',
          planTime: '1次/季度'
        },
        { projectDepartmentName: '加强安全物资、设备采购管理及检查，从源头上杜绝伪劣产品流入', planTime: '1次/季度' },
        { projectDepartmentName: '不发生新增职业病例', planTime: '0起' },
        { projectDepartmentName: '对识别出需进行职业卫生健康体检的岗位从业人员全覆盖', planTime: '100%' }
      ],
      total: 16,
      queryForm: {
        pageNo: 1,
        pageSize: 10
      }
    };
  },
  mounted() {
    this.tableList = this.onePage;
  },
  methods: {
    handleSizeChange(size) {
      this.queryForm.pageNo = 1;
      this.queryForm.pageSize = size;
      // this.getHiddenDangerPage();
    },
    handleCurrentChange(current) {
      if (current === 1) {
        this.tableList = this.onePage;
      } else {
        this.tableList = this.twoPage;
      }
      this.queryForm.pageNo = current;
      // this.getHiddenDangerPage();
    }
  }
};
</script>

<style lang="less" scoped>
.box {
  position: relative;
  margin-right: 16px;
  background: #ffffff;
  height: 360px;
  min-width: 390px;
  // flex: 2;
  width: 60%;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  .pageDiv {
    position: absolute;
    right: 0;
    bottom: 10px;
  }
}

// 隐藏滚动条
/deep/.el-table--scrollable-y .el-table__body-wrapper::-webkit-scrollbar {
  width: 7px;
}
/deep/.el-table--scrollable-y .el-table__body-wrapper::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}
</style>
