<!--
 * @Author: your name
 * @Date: 2021-11-09 15:17:54
 * @LastEditTime: 2021-12-25 12:30:43
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \交建\traffic-construction-web\src\views\pages\general-user\home-page\funds-charts.vue
-->
<template>
  <div class="box">
    <header-title header-text="安全生产经费投入" @getYear="getYear"></header-title>
    <div style="height:430px;min-width: 780px;" ref="echartRef"></div>
  </div>
</template>

<script>
import * as echarts from 'echarts';
import headerTitle from './headerTitle';
import { getFundPieChart } from '@/api/general-user/home-page';

export default {
  components: {
    headerTitle
  },
  data() {
    return {
      headerText: '设备状态',
      plandList: [],
      usedList: [],
      month: [],
      yeartime: ''
    };
  },
  mounted() {
    this.getFundPieChart();
    //取消resize事件监听
    this.$once('hook:beforeDestroy', () => {
      window.removeEventListener('resize', this.responsiveMethod);
    });
  },
  methods: {
    getYear(year) {
      this.yeartime = year;
      this.getFundPieChart();
    },
    //配置项
    initStatusNumConfig() {
      let chartDom = this.$refs.echartRef;
      let myChart = echarts.init(chartDom);
      let option = {
        //提示框组件
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            //去掉移动的指示线
            type: 'line'
          }
        },
        color: ['#038EF4', '#36D68D'],
        grid: {
          left: '30',
          right: '10',
          // bottom: '0',
          containLabel: true
        },
        legend: {
          data: ['计划经费', '实际使用经费'],
          textStyle: {
            fontSize: '14px',
            color: '#979FA8' //字体颜色
          },
          left: 'center',
          bottom: '25',
          itemWidth: 16, // 设置宽度
          itemHeight: 16, // 设置高度
          itemGap: 10 // 设置间距,
        },
        xAxis: {
          type: 'category',
          data: this.month,
          axisLine: {
            lineStyle: {
              type: 'solid',
              color: '#1890FF',
              width: '1'
            }
          },
          axisTick: {
            show: false
          },
          axisLabel: {
            textStyle: {
              color: '#C0C4CE'
            }
          }
        },
        yAxis: {
          name: '',
          max: this.max,
          type: 'value',
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          axisLabel: {
            textStyle: {
              color: '#C0C4CE'
            }
          },
          minInterval: 1,
          splitLine: {
            lineStyle: {
              color: '#f6f6f6'
            }
          }
        },
        // visualMap: {
        //   orient: 'horizontal',
        //   left: 'center',
        //   // min: 10,
        //   // max: 100,
        //   // text: ['High Score', 'Low Score'],
        //   // Map the score column to color
        //   dimension: 0,
        //   inRange: {
        //     color: ['#65B581', '#FFCE34', '#FD665F']
        //   }
        // },
        series: [
          {
            name: '计划经费',
            type: 'bar',
            data: this.plandList
          },
          {
            name: '实际使用经费',
            type: 'bar',
            data: this.usedList
          }
        ]
      };
      /* 图表响应式 */
      window.addEventListener('resize', this.responsiveMethod);
      myChart.setOption(option);
    },
    //设备数量
    getFundPieChart() {
      getFundPieChart({ year: this.yeartime }).then(res => {
        if (res.code === 200) {
          this.month = res.data.month;
          this.plandList = res.data.pland;
          this.usedList = res.data.used;
          this.initStatusNumConfig();
        }
      });
    },
    /* 图表响应式方法*/
    responsiveMethod() {
      echarts.init(this.$refs.echartRef).resize();
    }
  },
  created(){
    this.yeartime=new Date().getFullYear().toString();
  }
};
</script>

<style lang="less" scoped>
.box {
  margin-right: 16px;
  width: 60%;
  min-width: 780px;

  background: #ffffff;
  height: 460px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}
</style>
