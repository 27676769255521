/*
 * @Author: your name
 * @Date: 2021-11-19 09:53:48
 * @LastEditTime: 2021-12-24 10:34:27
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \交建\traffic-construction-web\src\api\general-user\home-page.js
 */
import request from '@/utils/request.js';
/**
 * 隐患统计分析
 * @returns {Promise}
 */
export function getPieChart(params) {
  return request({
    url: '/traffic-construction/getMajorHiddenDangerPage',
    method: 'get',
    params
  });
}
/**
 * 风险分级管控
 * @returns {Promise}
 */
export function getRickControlPieChart(params) {
  return request({
    url: '/traffic-construction/getRickControlPieChart',
    method: 'get',
    params
  });
}
/**
 * 施工安全检查
 * @returns {Promise}
 */
export function getScRecordPieChar(params) {
  return request({
    url: '/traffic-construction/getScRecordPieChar',
    method: 'get',
    params
  });
}
/**
 * 安全经费使用
 * @returns {Promise}
 */
export function getFundPieChart(params) {
  return request({
    url: '/traffic-construction//getFundPieChart1',
    method: 'get',
    params
  });
}
