<!--
 * @Author: your name
 * @Date: 2021-11-09 14:44:05
 * @LastEditTime: 2021-12-25 12:31:00
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \交建\traffic-construction-web\src\views\pages\general-user\home-page\risk-charts.vue
-->
<template>
  <div class="box">
    <header-title header-text="重大风险"></header-title>
    <div style="width: 100%">
      <el-table :data="tableList" style="width: 100%;cursor:pointer" max-height="250" @row-click="onRow">
        <el-table-column align="center" fixed width="80" type="index" label="序号"></el-table-column>
        <el-table-column align="center" fixed prop="projectDepartmentName" label="所属项目部"></el-table-column>
        <el-table-column align="center" fixed width="300" prop="problem" label="主要问题"></el-table-column>
      </el-table>
    </div>
    <div class="pageDiv">
      <el-pagination
        :total="total"
        :current-page="queryForm.pageNo"
        :page-size="queryForm.pageSize"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :page-sizes="[10, 20, 30, 50]"
        layout="total, sizes, prev, pager, next"
      ></el-pagination>
    </div>
    <list-display-dialog
      ref="formRef"
      dialog-title="隐患详情"
      :dialog-visible.sync="dialogVisible"
      :is-read.sync="isRead"
      :danger-type-list="dangerTypeList"
      :danger-level-list="dangerLevelList"
      :danger-category-list="dangerCategoryList"
      :project-list="projectList"
      :echo-form-data="echoFormData"
      @handel-success="handelSuccess"
    ></list-display-dialog>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash';
import headerTitle from './headerTitle';
import { getPieChart } from '@/api/general-user/home-page';
import listDisplayDialog from '../prevention-control/danger-treat/list-display/listDisplayDialog';
import { configDetailList, getDataRangByNature } from 'api/common';
export default {
  components: {
    headerTitle,
    listDisplayDialog
  },
  data() {
    return {
      tableList: [],
      dialogVisible: false,
      total: 0,
      queryForm: {
        pageNo: 1,
        pageSize: 10
      },
      projectList: [],
      isRead: true,
      echoFormData: null,
      dangerLevelList: [],
      dangerTypeList: [],
      dangerCategoryList: []
    };
  },
  mounted() {
    this.getHiddenDangerPage();
    this.getDataRangByNature();
    this.configDetailList();
  },
  methods: {
    //获取项目部
    getDataRangByNature() {
      getDataRangByNature({ orgId: '' }).then(res => {
        if (res.code === 200) {
          this.projectList = res.data;
        }
      });
    },
    configDetailList() {
      configDetailList({ module: 2 }).then(res => {
        if (res.code === 200) {
          const { two_category, two_level, two_type } = res.data;
          this.dangerLevelList = two_level;
          this.dangerTypeList = two_type;
          this.dangerCategoryList = two_category;
        }
      });
    },
    getHiddenDangerPage() {
      getPieChart({ ...this.queryForm }).then(res => {
        this.total = res.data.total;
        this.tableList = res.data.records;
      });
    },
    handleSizeChange(size) {
      this.queryForm.pageNo = 1;
      this.queryForm.pageSize = size;
      this.getHiddenDangerPage();
    },
    handleCurrentChange(current) {
      this.queryForm.pageNo = current;
      this.getHiddenDangerPage();
    },
    onRow(data) {
      this.isRead = true;
      this.dialogVisible = true;
      const obj = cloneDeep(data);
      this.$refs.formRef.initFormDetail(obj); //调用子组件的方法时使表单回显
    },
    handelSuccess(Boolean) {
      //true为返回列表第一页，否则为编辑，在当前页
      if (Boolean) {
        this.queryForm.pageNo = 1;
      }
      this.getHiddenDangerPage();
      //刷新列表
    }
  }
};
</script>

<style lang="less" scoped>
.box {
  position: relative;
  width: 100%;
  // margin-right: 16px;
  background: #ffffff;
  height: 360px;
  min-width: 390px;
  width: 40%;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  .pageDiv {
    position: absolute;
    right: 0;
    bottom: 10px;
  }
}
// 隐藏滚动条
/deep/.el-table--scrollable-y .el-table__body-wrapper::-webkit-scrollbar {
  width: 7px;
}
/deep/.el-table--scrollable-y .el-table__body-wrapper::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}
</style>
