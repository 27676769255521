<!--
 * @Author: your name
 * @Date: 2021-11-09 14:34:19
 * @LastEditTime: 2021-12-25 14:22:02
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \交建\traffic-construction-web\src\views\pages\general-user\home-page\hidden-charts.vue
-->
<template>
  <div class="box">
    <header-title header-text="领导交办事项"></header-title>
    <div>
      <el-table :data="tableList" style="min-width: 300px;cursor:pointer" max-height="350" @row-click="onRow">
        <el-table-column align="center" width="80" type="index" label="序号"></el-table-column>
        <el-table-column align="center" width="300" prop="projectDepartmentName" label="工作任务"></el-table-column>
        <el-table-column align="center" prop="planTime" label="办理人"></el-table-column>
      </el-table>
    </div>
    <div class="pageDiv">
      <el-pagination
        :total="total"
        :current-page="queryForm.pageNo"
        :page-size="queryForm.pageSize"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :page-sizes="[10, 20, 30, 50]"
        layout="total, sizes, prev, pager, next"
      ></el-pagination>
    </div>
    <el-dialog
      width="930px"
      title="领导交办事项"
      :visible.sync="visible"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      @close="handleColse"
    >
      <el-form ref="form" inline label-width="150px" :model="paramsForm">
        <el-row>
          <el-col :span="24">
            <el-form-item label="工作任务">
              <!-- <span>重大隐患</span> -->
              <el-input
                type="textarea"
                :rows="2"
                :autosize="{ minRows: 3, maxRows: 3 }"
                v-model="paramsForm.renwu"
                disabled
                placeholder="请输入"
                style="width: 730px"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="办理人">
              <el-input v-model="paramsForm.deviceName" disabled style="width: 300px"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="截止日期">
              <el-input v-model="paramsForm.shijian" disabled style="width: 300px"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="办理回复">
              <div class="block">
                <el-timeline>
                  <el-timeline-item v-for="(item, index) in activities" :key="index" :timestamp="item.timestamp">
                    <span class="nameDiv">{{ item.name }}</span>
                    <span style="font-size: 15px;">{{ item.zhiwei }}</span>
                    <div style="color: #666666;">
                      {{ item.concent }}
                    </div>
                  </el-timeline-item>
                </el-timeline>
              </div>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import headerTitle from './headerTitle';
// import { getPieChart } from '@/api/general-user/home-page';
export default {
  components: {
    headerTitle
  },
  data() {
    return {
      total: 2,
      queryForm: {
        pageNo: 1,
        pageSize: 10
      },
      tableList: [],
      visible: false,
      paramsForm: {
        renwu: '12月前整改所有的重大隐患',
        deviceName: '张三',
        shijian: '2021-11-11'
      },
      activities: [
        {
          name: '张三',
          zhiwei: '综合管理部 经理',
          concent: '已完成重大隐患整改',
          timestamp: '2021-12-14'
        },
        {
          name: '张三',
          zhiwei: '综合管理部 经理',
          concent: '已完成所有重大隐患复核',
          timestamp: '2021-12-13'
        }
      ]
    };
  },
  mounted() {},
  methods: {
    onRow(data) {
      this.visible = true;
    },
    handleColse() {
      this.visible = false;
    },
    handleSizeChange(size) {
      this.queryForm.pageNo = 1;
      this.queryForm.pageSize = size;
      // this.getHiddenDangerPage();
    },
    handleCurrentChange(current) {
      this.queryForm.pageNo = current;
      // this.getHiddenDangerPage();
    }
  }
};
</script>

<style lang="less" scoped>
.box {
  position: relative;
  // margin-right: 16px;
  width: 40%;
  min-width: 390px;
  background: #ffffff;
  height: 460px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  .pageDiv {
    position: absolute;
    right: 0;
    bottom: 10px;
  }
}
.nameDiv {
  color: #008ff8;
  font-size: 15px;
  margin-right: 10px;
}
.block {
  height: 500px;
  overflow: auto;
  width: 728px;
  padding-top: 20px;
  background-color: #f5f7fa;
  border-radius: 3px;
  border: 1px solid #e4e7ed;
}

// 隐藏滚动条
/deep/.block::-webkit-scrollbar {
  width: 7px;
}
/deep/.block::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}
// 隐藏滚动条
/deep/.el-table--scrollable-y .el-table__body-wrapper::-webkit-scrollbar {
  width: 7px;
}
/deep/.el-table--scrollable-y .el-table__body-wrapper::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}
</style>
