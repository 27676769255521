<!--
 * @Author: your name
 * @Date: 2021-11-09 14:41:10
 * @LastEditTime: 2021-11-09 14:43:09
 * @LastEditors: your name
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \交建\traffic-construction-web\src\views\pages\general-user\home-page\headerTitle.vue
-->
<template>
  <div class="header-title">
    <i></i>{{ headerText }}
    <div v-if="headerText === '安全生产经费投入'" class="yeartime">
      <el-date-picker
        v-model="time.year"
        :clearable="false"
        type="year"
        placeholder="选择年"
        value-format="yyyy"
        :picker-options="limtime"
        @change="getYear"
      ></el-date-picker>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    headerText: {
      type: String
    }
  },
  data() {
    return {
      time: {
        year: ''
      },
      limtime: {
        //选择前8年
        disabledDate(time) {
          return time.getTime() < Date.parse(new Date().getFullYear() - 8) || time.getTime() > Date.now();
        }
      }
    };
  },
  methods: {
    getYear(year) {
      this.$emit('getYear', year);
    },
    getNowYear() {
      let time = new Date();
      let year = time.getFullYear().toString();
      this.time.year = year;
    }
  },
  created() {
    this.getNowYear();
  }
};
</script>

<style lang="less" scoped>
.header-title {
  height: 48px;
  line-height: 48px;
  display: flex;
  align-items: center;
  color: #303133;
  font-weight: bold;
  font-size: 16px;
  border-bottom: 1px solid #e8e8e8;
  position: relative;
  i {
    display: inline-block;
    width: 6px;
    height: 24px;
    background: #005aab;
    margin: 0 8px 0 12px;
  }
}
.yeartime {
  position: absolute;
  right: 10px;
}
</style>
