<!--
 * @Author: your name
 * @Date: 2021-11-09 09:55:12
 * @LastEditTime: 2021-12-23 15:32:05
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \交建\traffic-construction-web\src\views\pages\general-user\home-page\index.vue
-->
<template>
  <div class="center">
    <div class="oneDiv">
      <hidden-charts />
      <risk-charts />
    </div>
    <div class="twoDIv">
      <construction-charts />
      <funds-charts />
    </div>
  </div>
</template>
<script>
import hiddenCharts from './hidden-charts.vue';
import riskCharts from './risk-charts.vue';
import constructionCharts from './construction-charts.vue';
import fundsCharts from './funds-charts.vue';

export default {
  components: {
    hiddenCharts,
    riskCharts,
    constructionCharts,
    fundsCharts
  },
  data() {
    return {};
  },
  created() {},
  methods: {}
};
</script>
<style lang="less" scoped>
.center {
  height: 100%;
  .oneDiv {
    min-height: 360px;
    width: 100%;
    margin-bottom: 16px;
    display: flex;
    // justify-content: center;
    // align-items: center;
  }
  .twoDIv {
    display: flex;
    height: 450px;
    width: 100%;
  }
}
</style>
